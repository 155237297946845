<template>
    <Dialog
        v-model:visible="_confirmRescheduleDialog"
        class="p-fluid"
        :style="{ width: '450px' }"
        header="Selecione o motivo do reagendamento "
        :draggable="false"
        :closable="false"
        :modal="true"
    >
        <div class="flex flex-column gap-5">
            <Dropdown
                id="motivoReagendamento"
                v-model="models.motivoReagendamento"
                :options="computeds.listaMotivosReagendamentos"
                optionLabel="label"
                optionValue="value"
                :showClear="true"
                placeholder="Selecionar motivo"
            />
            <div v-if="models.motivoReagendamento === enumMotivoReagendamento.Outros" class="flex flex-column gap-2">
                <label for="descricao">Descreva o motivo do reagendamento</label>
                <InputText v-model="models.descricaoMotivo" maxlength="500" id="descricao" required="true" autofocus />
            </div>
        </div>
        <template #footer>
            <Button label="Fechar" class="p-button-text" @click="closeDialog" />
            <Button
                label="Confirmar"
                class="p-button p-button-primary"
                :disabled="
                    models.motivoReagendamento === enumMotivoReagendamento.Outros
                        ? !models.motivoReagendamento || !models.descricaoMotivo
                        : !models.motivoReagendamento
                "
                @click="confirmReschedule"
                :loading="loadingReagendar"
            />
        </template>
    </Dialog>
</template>
<script setup>
import { getClientBase } from '@/services/http';
import { reactive, defineProps, computed, defineEmits, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';
import StatusAgendamento from '@/enums/StatusAgendamento';
import enumMotivoReagendamento, { MotivoReagendamentoEnums } from '@/enums/EnumMotivoReagendamento';
import EnumMotivoReagendamento from '@/enums/EnumMotivoReagendamento';
import AreaMedicaService from '@/services/AreaMedicaService';

const props = defineProps({
    appointments: Array,
    agendamentoStatus: Object,
    confirmRescheduleDialog: Boolean,
    loadingReagendar: Boolean,
    appointmentSelectedMenu: Object
});
const emits = defineEmits([
    'closeDialog',
    'update:agendamentoStatus',
    'update:submitted',
    'update:confirmRescheduleDialog',
    'update:appointmentCompleted',
    'update:appointmentStarted',
    'update:loadingReagendar',
    'update:appointmentSelectedMenu'
]);

const _agendamentoStatus = computed({
    get() {
        return props.agendamentoStatus;
    },
    set(val) {
        emits('update:agendamentoStatus', val);
    }
});

const _confirmRescheduleDialog = computed({
    get() {
        return props.confirmRescheduleDialog;
    },
    set(val) {
        emits('update:confirmRescheduleDialog', val);
    }
});

const models = reactive({
    motivoReagendamento: null,
    descricaoMotivo: '',
    confirmRescheduleDialog: false
});

const computeds = computed(() => {
    return {
        listaMotivosReagendamentos: Object.entries(EnumMotivoReagendamento)
            .map((motivo) => {
                return {
                    label: motivo[1],
                    value: motivo[0]
                };
            })
            .filter((motivo) => !MotivoReagendamentoEnums.excluirSelecao.includes(motivo.value))
    };
});

const $toast = useToast();

function closeDialog() {
    emits('update:confirmRescheduleDialog', false);
    models.motivoReagendamento = null;
    models.descricaoMotivo = '';
    models.confirmRescheduleDialog = false;
}

async function confirmReschedule() {
    emits('update:submitted', true);

    const appointmentSelectedMenu = { ...props.appointmentSelectedMenu };
    if (appointmentSelectedMenu.status.id === StatusAgendamento.REAGENDADO) {
        closeDialog();
        $toast.add({
            severity: 'warn',
            summary: 'Informação',
            detail: 'Agendamento já foi reagendado',
            life: 3000
        });
        return;
    }

    try {
        emits('update:loadingReagendar', true);
        const formAgendamentoStatus = {
            descricao: 'Status do agendamento alterado para Reagendado.',
            agendamento: appointmentSelectedMenu,
            status: { id: StatusAgendamento.REAGENDADO },
            motivoReagendamento: {
                motivo: models.motivoReagendamento,
                descricao: models.descricaoMotivo
            }
        };
        if (
            appointmentSelectedMenu.status.id === StatusAgendamento.EM_ANDAMENTO ||
            appointmentSelectedMenu.status.id === StatusAgendamento.AGUARDANDO_CONF_COLABORADOR
        ) {
            const { data } = await getClientBase().post('agendamento_status/envia-email-solicitante', {
                agendamento: appointmentSelectedMenu,
                descricao: appointmentSelectedMenu.descricaoEmail,
                motivoReagendamento: {
                    motivo: models.motivoReagendamento,
                    descricao: models.descricaoMotivo
                }
            });

            appointmentSelectedMenu.user = data.user;
            appointmentSelectedMenu.status = data.status;
            appointmentSelectedMenu.msgErroProcessamento = data.msgErroProcessamento;
            appointmentSelectedMenu.erroProcessamento = data.erroProcessamento;
            appointmentSelectedMenu.etapaProcessamento = data.etapaProcessamento;
            appointmentSelectedMenu.sequencialFicha = data.sequencialFicha;
            _agendamentoStatus.value.reagendado = _agendamentoStatus.value.reagendado + 1;

            const itemDaLista = props.appointments.find((p) => p.id == appointmentSelectedMenu.id);

            if (itemDaLista) {
                itemDaLista.user = appointmentSelectedMenu.user;
                itemDaLista.status = appointmentSelectedMenu.status;
                itemDaLista.msgErroProcessamento = appointmentSelectedMenu.msgErroProcessamento;
                itemDaLista.erroProcessamento = appointmentSelectedMenu.erroProcessamento;
                itemDaLista.etapaProcessamento = appointmentSelectedMenu.etapaProcessamento;
                itemDaLista.sequencialFicha = appointmentSelectedMenu.sequencialFicha;
            }

            closeDialog();
            emits('update:appointmentCompleted', false);
            emits('update:appointmentStarted', false);
            emits('update:loadingReagendar', false);
            emits('update:appointmentSelectedMenu', appointmentSelectedMenu);
            $toast.add({
                severity: 'success',
                summary: 'Email enviado com sucesso',
                detail: 'Email enviado com sucesso ao solicitante.',
                life: 5000
            });
            return;
        }

        const { data } = await new AreaMedicaService('/agendamento_status').save(formAgendamentoStatus);

        closeDialog();

        appointmentSelectedMenu.user = data.user;
        appointmentSelectedMenu.status = data.agendamento.status;
        appointmentSelectedMenu.msgErroProcessamento = data.agendamento.msgErroProcessamento;
        appointmentSelectedMenu.erroProcessamento = data.agendamento.erroProcessamento;
        appointmentSelectedMenu.etapaProcessamento = data.agendamento.etapaProcessamento;
        appointmentSelectedMenu.sequencialFicha = data.agendamento.sequencialFicha;
        _agendamentoStatus.value.reagendado = _agendamentoStatus.value.reagendado + 1;

        const itemDaLista = props.appointments.find((p) => p.id == appointmentSelectedMenu.id);
        if (itemDaLista) {
            itemDaLista.user = appointmentSelectedMenu.user;
            itemDaLista.status = appointmentSelectedMenu.status;
            itemDaLista.msgErroProcessamento = appointmentSelectedMenu.msgErroProcessamento;
            itemDaLista.erroProcessamento = appointmentSelectedMenu.erroProcessamento;
            itemDaLista.etapaProcessamento = appointmentSelectedMenu.etapaProcessamento;
            itemDaLista.sequencialFicha = appointmentSelectedMenu.sequencialFicha;
        }

        emits('update:appointmentCompleted', false);
        emits('update:appointmentStarted', false);
        emits('update:loadingReagendar', false);
        emits('update:appointmentSelectedMenu', appointmentSelectedMenu);
        $toast.add({
            severity: 'success',
            summary: 'Informações salvas com sucesso',
            detail: 'O agendamento está aberto para reagendamento.',
            life: 5000
        });
    } catch (err) {
        emits('update:loadingReagendar', false);
        $toast.add({
            severity: 'error',
            summary: 'Erro',
            detail: err?.response?.data?.message || err?.message,
            life: 3000
        });
    }
}

defineExpose({ confirmReschedule });
</script>
