export class MotivoReagendamentoEnums {
    static chaves = {
        FaltaConsulta: 'FaltaConsulta',
        AlterarClinicaColaborador: 'AlterarClinicaColaborador',
        AlterarDiaColaborador: 'AlterarDiaColaborador',
        AlterarCidadeColaborador: 'AlterarCidadeColaborador',
        AlterarPeriodoColaborador: 'AlterarPeriodoColaborador',
        AlterarClinicaCliente: 'AlterarClinicaCliente',
        AlterarDiaCliente: 'AlterarDiaCliente',
        AlterarCidadeCliente: 'AlterarCidadeCliente',
        AlterarPeriodoCliente: 'AlterarPeriodoCliente',
        NaoAtendidoErroPrestadorDuploVinculo: 'NaoAtendidoErroPrestadorDuploVinculo',
        NaoAtendidoErroPrestadorSemAgendamento: 'NaoAtendidoErroPrestadorSemAgendamento',
        NaoAtendidoPorFaltaPagamento: 'NaoAtendidoPorFaltaPagamento',
        NaoAtendidoPorFaltaKit: 'NaoAtendidoPorFaltaKit',
        Outros: 'Outros',
        MotivosInternos: 'MotivosInternos'
    };
    static excluirSelecao = [this.chaves.FaltaConsulta];
}

const EnumMotivoReagendamento = {
    AlterarClinicaColaborador: 'Colaborador solicitou alterar a clínica',
    AlterarDiaColaborador: 'Colaborador solicitou alterar o dia',
    AlterarCidadeColaborador: 'Colaborador solicitou alterar a cidade',
    AlterarPeriodoColaborador: 'Colaborador solicitou alterar o período',
    AlterarClinicaCliente: 'Cliente solicitou alterar a clínica',
    AlterarDiaCliente: 'Cliente solicitou alterar o dia',
    AlterarCidadeCliente: 'Cliente solicitou alterar a cidade',
    AlterarPeriodoCliente: 'Cliente solicitou alterar o período',
    NaoAtendidoErroPrestadorDuploVinculo: 'Colaborador não foi atendido por erro do prestador duplo vínculo',
    NaoAtendidoErroPrestadorSemAgendamento: 'Colaborador não foi atendido por erro do prestador alegar que não tem agendamento',
    NaoAtendidoPorFaltaPagamento: 'Colaborador não foi atendido por falta de pagamento',
    NaoAtendidoPorFaltaKit: 'Colaborador não foi atendido por falta de kit',
    Outros: 'Outros',
    [MotivoReagendamentoEnums.chaves.FaltaConsulta]: 'Falta consulta'
};

export default EnumMotivoReagendamento;
